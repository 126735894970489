<template>
  <div class="main">
    <h1 class="title is-1">Directory of Accredited Firms</h1>

    <div class="field has-addons my-5">
      <div class="control is-expanded">
        <input class="input" type="text" placeholder="Search for a firm" v-model="searchInput" :disabled="searchFilter">
      </div>
      <div class="control">
        <button class="button is-primary"
                v-on:click="searchFilter = searchInput.toLowerCase()"
                v-if="!searchFilter"
        >
          Search
        </button>
        <button class="button"
                v-on:click="searchFilter = null; searchInput = null"
                v-else
        >
          Clear
        </button>
      </div>
    </div>

    <div v-if="loading === true" class="column is-half">
      <Loader></Loader>
    </div>
    <div v-else class="table-container">
      <table class="table">
        <thead>
        <tr>
          <th>
            Office/Organization
          </th>
          <th>
            Address
          </th>
          <th>
            Contact Person/Telephone No.
          </th>
        </tr>
        </thead>

        <tbody>
          <tr v-for="firm in firms" :key="firm.name" v-if="(!searchFilter || firm.name.toLowerCase().includes(searchFilter))">
            <td>{{firm.name}}</td>
            <td>{{firm.address}}</td>
            <td>{{firm.contact}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {supabase} from "../supabaseClient";
import Loader from "../components/Loader";
export default {
  name: "Directory",
  title: "Directory | Hirati: Ateneo Law School Career Fair 2023",
  components: {Loader},
  data() {
    return {
      firms: [],
      loading: true,
      searchInput: null,
      searchFilter: null,
    }
  },
  methods: {
    async initializePage(select) {
      this.loading = true
      this.firms = []

      let query = supabase
          .from('directory')
          .select()
          .order('name', {ascending: true})

      const {data, error} = await query
      this.firms = data
      if (error) { console.log(error) }
      console.log(data)
      this.loading = false
    }
  },
  mounted() {
    this.initializePage(this.$route.params.select)
  }
}
</script>

<style scoped>
.main {
  padding: 6.9%;
}

td {
  white-space: pre-wrap !important;
}
</style>